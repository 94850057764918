import React from "react";
import styled from "styled-components";
import Doc1 from "../assets/Advisor/양용준 원장님.png";
import Doc2 from "../assets/Advisor/강동우 교수님.png";

// Styled components
const Container = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 20px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const AdvisorCard = styled.div`
  width: 45%;
  margin-bottom: 30px;
  text-align: center;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ProfileImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
`;

const NameTitle = styled.h2`
  font-size: 1.5em;
  margin: 10px 0;
`;

const HospitalName = styled.h3`
  font-size: 1.2em;
  margin-bottom: 10px;
  color: #333;
`;

const Bio = styled.p`
  font-size: 0.9em;
  color: #555;
  line-height: 1.5;
`;

const DetailsButton = styled.a`
  border: 1px solid #000;
  background-color: transparent;
  color: #000;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.8rem;
  cursor: pointer;
  margin-top: 10px;
  text-decoration-line: none;

  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.1);
  }
`;

const Advisor: React.FC = () => {
  return (
    <Container>
      <AdvisorCard>
        <ProfileImage src={Doc1} alt="양용준 원장" />
        <HospitalName>오늘정신건강의학과 의원</HospitalName>
        <NameTitle>양용준 원장</NameTitle>
        <Bio>
          <strong>학력</strong>
          <br />
          고려대학교 심리학과 졸업
          <br />
          가톨릭대학교 의과대학 졸업
          <br />
          <br />
          <strong>경력</strong>
          <br />
          (현) 오늘정신건강의학과 원장
          <br />
          (현) 가톨릭대학교 서울성모병원 외래 교수
          <br />
          김해시 정신건강복지센터 센터장 역임
          <br />
          교육부 자문전문의 외 다수
          <br />
          <br />
          <DetailsButton
            href="https://oneulclinic.net/person"
            target="_blank"
            rel="noopener noreferrer"
          >
            More
          </DetailsButton>
        </Bio>
      </AdvisorCard>

      <AdvisorCard>
        <ProfileImage src={Doc2} alt="강동우 교수" />
        <HospitalName>서울성모병원 정신건강의학과</HospitalName>
        <NameTitle>강동우 교수</NameTitle>
        <Bio>
          <strong>학력</strong>
          <br />
          가톨릭대학교 대학원 의학과 정신과학 박사
          <br />
          가톨릭대학교 의과대학 의학 학사
          <br />
          <br />
          <strong>경력</strong>
          <br />
          서울성모병원 정신건강의학과 조교수
          <br />
          서초구치매안심센터 센터장
          <br />
          국가연구개발과제 평가위원 외 다수
          <br />
          <br />
          <DetailsButton
            href="https://www.cmcseoul.or.kr/page/doctor/151/D0000331"
            target="_blank"
            rel="noopener noreferrer"
          >
            More
          </DetailsButton>
        </Bio>
      </AdvisorCard>
    </Container>
  );
};

export default Advisor;
