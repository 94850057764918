import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import HWimg from "./assets/vwfRcF.png";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { CiInstagram, CiLink } from "react-icons/ci";
import Hwang01 from "./assets/Hwang/SleepingChinchilla.jpeg";
import Hwang02 from "./assets/Hwang/Feast.jpeg";
import Byeon01 from "./assets/Byeon/Acacia_Peacock_Improvement_of_depression_24-01.jpeg";
import Byeon02 from "./assets/Byeon/Acacia_Peacock_Improvement_of_depression_24-02.jpeg";
import Byeon03 from "./assets/Byeon/Acacia_Peacock_Improvement_of_depression_24-03.jpeg";
import Byeon04 from "./assets/Byeon/Acacia_Peacock_Improved_concentration_24-01.jpeg";
import Byeon05 from "./assets/Byeon/Acacia_Peacock_Improved_concentration_24-02.jpeg";
import Advisor from "./components/Advisor";

const Container = styled.div`
  font-family: Arial, sans-serif;
  color: #333;
  line-height: 1.6;
`;

const MainSection = styled.section`
  padding: 80px 20px;
  text-align: center;
  color: white;
  background-image: url(${HWimg});
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.651);
    z-index: -1;
  }

  h2 {
    font-size: 2em;
    margin-bottom: 20px;
    color: white;
  }

  p {
    max-width: 800px;
    margin: 0 auto;
    color: white;
  }
  @media (max-width: 768px) {
    word-break: keep-all;
    white-space: normal;
    overflow-wrap: break-word;
  }
`;

const Image = styled.img`
  display: none;
`;

const ArtistsSection = styled.section`
  padding: 20px;
  background-color: #f9f9f9;
  text-align: center;

  h2 {
    font-size: 2em;
    margin-bottom: 20px;
    color: #01211a;
  }
`;

const ArtistCard = styled.div`
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;

  h3 {
    font-size: 1.5em;
  }
`;

const IconPositonInsta = styled(CiInstagram)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #000000;
  cursor: pointer;
  margin-top: 5px;
`;

const IconPositonLink = styled(CiLink)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #000000;
  cursor: pointer;
  margin-top: 5px;
`;

const ArtistInfo = styled.div`
  display: block;
  align-items: center;
  justify-content: center;
`;

const Artworks = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  @media (max-width: 768px) {
    display: block;
    align-items: center;
  }

  > div {
    flex-basis: calc(33.33% - 20px);
    margin-bottom: 20px;
  }
`;

const ImageBox = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
  position: relative;

  p {
    font-size: 10px;
  }
`;

const SoldOutBadge = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  /* background-color: red; */
  border: 1px solid #000;
  color: black;
  padding: 2px 6px;
  font-size: 10px;
  border-radius: 4px;

  &::before {
    content: "";
    display: inline-block;
    width: 6px;
    height: 6px;
    background-color: red;
    border-radius: 50%;
    margin-right: 4px;
  }
`;

const DetailsButton = styled.button`
  border: 1px solid #000;
  background-color: transparent;
  color: #000;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.8rem;
  cursor: pointer;
  margin-top: 10px;

  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.1);
  }
`;

const ArtImage = styled.img`
  width: 300px;
  height: 300px;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const Modal = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  width: 70%;
  max-width: 900px;
  height: 500px;
  padding: 20px;
  overflow: hidden;
  z-index: 10;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 80%;
    height: auto;
    max-height: 70vh;
    overflow-y: auto;
    justify-content: center;
    align-items: center;
  }
`;

const ModalImage = styled.div<ModalImageProps>`
  width: 50%;
  background-image: url(${(props) => props.image});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 8px;

  @media (max-width: 768px) {
    width: 100%;
    height: 300px;
    margin-bottom: 20px;
  }
`;

const ModalTextSection = styled.div`
  width: 50%;
  padding: 20px;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 100%;
    padding: 10px;
  }
`;

interface ModalImageProps {
  image: string;
}

const ModalText1 = styled.p`
  font-size: 0.8rem;
  @media (max-width: 768px) {
    word-break: keep-all;
    white-space: normal;
    overflow-wrap: break-word;
  }
`;

const ModalText2 = styled.p`
  font-size: 0.7rem;
  @media (max-width: 768px) {
    word-break: keep-all;
    white-space: normal;
    overflow-wrap: break-word;
  }
`;

const CloseButton = styled.button`
  background-color: #444;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const HealingEffectsSection = styled.section`
  padding: 20px;
  text-align: center;
  margin-bottom: 50px;
  h2 {
    font-size: 2em;
    margin-bottom: 20px;
    color: #01211a;
  }

  p {
    max-width: 800px;
    margin: 0 auto;
  }
  @media (max-width: 768px) {
    word-break: keep-all;
    white-space: normal;
    overflow-wrap: break-word;
  }
`;
const Subtext = styled.p`
  font-size: 8px;
`;

const App: React.FC = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedArtwork, setSelectedArtwork] = useState<any>(null);

  const modalTextRef = useRef<HTMLDivElement>(null);

  const handleModalClickOutside = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";

      if (modalTextRef.current) {
        modalTextRef.current.scrollTop = 0;
      }
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isModalOpen]);

  const openModal = (artwork: string) => {
    const artworkData = getArtworkDescription(artwork);
    setSelectedArtwork(artworkData);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedArtwork(null);
  };

  const getArtworkDescription = (artwork: string) => {
    switch (artwork) {
      case "SLEEPING CHINCHILLA":
        return {
          image: "/assets/Hwang/SleepingChinchilla.jpeg",
          title: "Sleeping Chinchilla",
          description: (
            <>
              <ModalText1>
                <em>Artist's Note : </em> 친칠라는 근심 없이 곤히 잠들어 있다.
                <br /> 푸릇한 잔디가 부드럽게 둘러싸고 있으며, 바람에 살랑이는
                풀과 잔잔히 흐르는 호수 물결이
                <br /> 마치 자장가처럼 그의 휴식을 돕고 있다.
                <br />
                <br />
                <b>효과 : #숙면 #안정 #수면유도 #이완 #멜라토닌 분비</b>
              </ModalText1>
              <br />
              <ModalText2>
                <strong>숙면을 촉진하는 예술적 접근 : </strong> "Sleeping
                chinchilla"는 현대인의 수면 질 향상과 심리적 안정을 목표로, 수면
                과학과 예술 치료의 원리를 융합한 작품입니다.
                <br />
                <br /> 색채 심리학과 수면 연구 결과를 접목하여, 숙면을 유도하는
                색상을 선택했습니다. 풀과 나무의 부드러운 초록빛, 호수와 하늘의
                고요한 푸른빛은 멜라토닌 분비를 촉진하고 심박수를 낮추는 효과가
                있어 수면의 질을 높입니다.
                <br />
                <br />
                이는 자연과 단절된 도시 현대인들에게 평온한 수면 환경을
                시각적으로 제공합니다. 형태 심리학과 수면 의학의 관점에서,
                작품은 수면을 유도하는 부드러운 곡선을 강조합니다. 포근하게
                웅크린 친칠라의 모습과 살랑이는 잔디의 곡선은 시각적 편안함을
                주어 긴장을 풀고 수면으로의 진입을 돕습니다. <br />
                <br />
                이는 각진 형태보다 둥근 형태가 수면에 도움이 된다는 연구 결과를
                반영한 것입니다. 환경 심리학과 수면 위생 원칙을 적용하여, 작품은
                숙면을 돕는 자연 요소들을 담았습니다.
                <br />
                <br />
                잔디, 호수, 나무, 하늘 등은 스트레스를 감소시키고 심신의 이완을
                유도하여 깊은 수면으로 안내합니다. 이를 통해 감상자는 자연
                속에서 편안히 잠드는 순간을 상상하며 실제 수면의 질을 개선할 수
                있습니다. 구성 면에서는 수면 심리학의 원리를 반영했습니다.
                단순하고 정돈된 구도는 뇌의 활동을 줄이고 마음을 고요하게 만들어
                수면 준비에 도움을 줍니다. 불필요한 자극을 최소화하여 관람자가
                평온한 수면 상태로 쉽게 전이될 수 있도록 했습니다.
                <br />
                <br /> 수면 의학의 관점에서, 작품은 이상적인 수면 환경을
                시각화합니다. 평화롭게 잠든 친칠라의 모습은 완벽한 숙면의 순간을
                상징하며, 이는 감상자에게 긍정적인 수면 이미지를 심어줍니다.
                이러한 시각적 암시는 실제 수면의 질을 향상시키는 데 도움이 될 수
                있습니다. <br />
                <br />
                "Sleeping chinchilla"는 이처럼 수면 과학과 예술의 원리를
                세심하게 융합하여 현대인의 숙면을 돕고 수면의 질을 개선하는 것을
                주요 목표로 합니다. 잠들기 전 이 작품을 감상함으로써, 관람자는
                더 쉽게 평온한 수면 상태에 도달하고 깊은 휴식을 취할 수 있을
                것입니다.
                <br />
                <br />- 서울성모병원 정신건강의학과 강동우 교수
              </ModalText2>
            </>
          ),
        };
      case "Feast":
        return {
          image: "/assets/Hwang/Feast.jpeg",
          title: "Feast",
          description: (
            <>
              <ModalText1>
                <em>Artist's Note : </em> 한 식탁에 다 같이 둘러 앉아 식사를
                하는 따뜻한 모습을 담았습니다. <br />
                저녁 식탁과 함께 보이는 막 구운 노릇노릇 탐스러운 칠면조 요리와
                신선한 과일과 빵, 함께 곁들이는 샴페인 등은 <br />
                각각의 색깔을 가지고 식욕증진에 도움이 되는 컬러들을 담았습니다.
                <br />
                빨강색은 식욕을 자극하고 심박수를 높이는 것으로 알려져 있습니다.
                <br />
                <Subtext>
                  (Singh, S. (2006). Impact of color on marketing. Management
                  Decision, 44(6), 783-789)
                </Subtext>
                주황색은 신선함과 식욕 자극과 연관됩니다. 노랑색은 행복감과
                연관되며 신진대사를 증가시킬 수 있습니다. <br />
                따뜻한 갈색은 편안함과 자연스러움을 느끼게 합니다. 한 끼지만
                작은 축제처럼 차려져 있는 음식과 <br />
                이를 즐기는 친칠라들의 모습은 특히 음식 이미지가 뇌 활동에
                영향을 미치고 건강한 식습관 유도에 도움이 된다는 연구
                <br />
                를참고하였습니다.
                <br />
                <Subtext>
                  (Zellner, D. A., & Durlach, P. (2003). Effect of color on
                  expected and experienced refreshment, intensity, and liking of
                  beverages. The American Journal of Psychology, 116(4),
                  633-647)
                </Subtext>
                <br />
                <b>
                  효과 : #식욕 #행복감 #맛 #신선함 #따뜻함 #소중함 #즐거움 #입맛
                </b>
              </ModalText1>
              <br />
              <ModalText2>
                <strong>식욕을 증진하는 예술적 접근 : </strong> "Feast"이 그림은
                식욕 촉진에 효과적인 요소들을 잘 통합하고 있어, 관람자의 식욕을
                자극하고 있습니다.
                <br />
                <br />이 작품에서 주요하게 사용된 색상들—노랑, 빨강, 주황—은
                각각 행복감과 연관되어 신진대사를 증가시키며(노랑), 식욕을
                자극하고 기대감을 높이는(빨강), 신선함과 연계되는(주황) 특성을
                지니고 있습니다.
                <br />
                <br />
                이러한 색상들은 음식의 매력을 극대화하고, 식사를 더욱 돋보이게
                만드는 역할을 합니다. 질감과 형태 또한 중요한 역할을 합니다.
                식탁 위의 음식들은 다양한 질감을 보여주어 입맛을 돋우며, 음식의
                실제 맛과 신선함을 강조하는 요소로 작용합니다. 특히, 음식의 둥근
                형태는 달콤함과 연관되어 갈망을 유발하며, 대비되는 색상 사용은
                음식 항목을 더욱 돋보이게 만듭니다. 이러한 시각적 요소들의
                조합은 최근의 연구에서 중요하게 다뤄지는 주제와 맞닿아 있습니다.
                <br />
                <br />
                예를 들어, ‘Spence’의 연구(Spence C, Okajima K, Cheok AD, Petit
                O, Michel C. Eating with our eyes: From visual hunger to digital
                satiation. Brain Cogn. 2016)에서는 시각적 신호가 식욕과 식행동에
                미치는 영향을 검토하며 "시각적 배고픔"이라는 개념을 소개합니다.
                <br />
                <br />
                또한, 영양가 높은 음식 이미지가 뇌의 주의를 더 많이 끌고 보상
                시스템을 활성화한다는 점이 확인되었습니다. 이는 그림 속 음식
                이미지가 관람자의 식욕을 자극하여 실제 행동에 영향을 미칠 수
                있음을 시사합니다. 종합적으로 볼 때, 이 그림은 식욕을 자극하는
                시각적 요소들을 효과적으로 활용하여 관람자의 식욕에 긍정적인
                영향을 미칠 가능성을 내포하고 있습니다.
                <br />
                <br />
                이는 창의적 예술치료와 같은 분야에서도 활용될 수 있는 가치 있는
                특성이며, ‘식사는 단순한 영양 섭취가 아닌 삶의 만족감을 주고
                행복을 증진시켜주는 소중한 행위’라는 점을 우리에게 일깨워줍니다.
                <br />
                <br />- 서울성모병원 정신건강의학과 강동우 교수
              </ModalText2>
            </>
          ),
        };
      case "아카시아공작 우울증 완화 24_01":
        return {
          image: Byeon01,
          title: "아카시아공작 우울증 완화 24_01",
          description: (
            <>
              <ModalText1>
                <em>Artist's Note : </em> "아카시아 공작"은 평범한 아카시아 잎이
                화려한 공작 깃털로 변모하는 과정을 섬세하게 표현합니다. <br />
                교차하는 잎의 줄기가 만들어내는 감각적인 색채 속에서, <br />이
                변화는 우리 내면의 숨겨진 가능성을 상징합니다.
                <br />
                <br />
                작가는 자연과의 조화를 통해 세상과 예술을 바라보며, <br />
                흔히 부차적으로 여겨지는 잎이 꽃보다 더 아름답게 변하는 모습을
                그립니다.
                <br />
                이를 통해 주목받지 못했던 존재의 잠재적 아름다움을 강조하고,{" "}
                <br />
                모든 이가 내면에 특별한 가치를 지니고 있음을 일깨웁니다.
                <br />
                <br />{" "}
                <b>
                  효과 : #우울감 개선, #스트레스 감소, #심리 안정, #긍정적 변화,
                  #희망
                </b>
              </ModalText1>
              <br />
              <ModalText2>
                <strong>우울감을 완화시키는 예술적 접근 : </strong> "아카시아
                공작"은 환자의 우울감 개선과 정서적 치유를 목표로, 자연의
                치유력과 예술 치료의 원리를 깊이 있게 융합한 작품입니다. <br />
                <br />이 작품은 밝은 녹색 아카시아 잎사귀들 사이에 숨겨진 화려한
                파란색과 황금색의 공작새 깃털을 섬세하게 표현함으로써, 자연
                이미지의 치유 효과를 극대화합니다. 스티그스도터와 그란(2004)의
                연구에서 밝혀진 바와 같이, 이러한 자연 모티프는 우울증 환자들의
                스트레스를 현저히 감소시키고 전반적인 기분을 개선하는 데 큰
                도움을 줍니다. 특히 병원이나 도시와 같은 인공적 환경에서, 이
                작품은 환자들에게 자연과의 연결감을 제공하여 심리적 안정과
                정서적 회복을 촉진합니다.
                <br />
                <br />
                색채 심리학의 관점에서, 작품에 사용된 녹색은 우울감을 완화하고
                내면의 평화를 유도하는 효과가 있습니다. 공작새 깃털의 파란색과
                황금색은 희망과 활력을 상징하여, 우울한 환자들의 기분을
                고양시키고 긍정적 변화에 대한 기대를 심어줍니다. 이는 색채를
                통한 감정 조절과 기분 전환의 원리를 적용한 것입니다.
                <br />
                <br />
                디에테 등(2003)의 연구결과를 반영하여, 이 작품은 의료 환경을
                더욱 친근하고 편안한 공간으로 변모시키는 역할을 합니다. 일상적인
                잎사귀가 화려한 공작 깃털로 변하는 모습은 환자들에게 변화와
                성장의 가능성을 시각적으로 제시하며, 이는 우울증 치료에서 중요한
                '희망의 메시지'를 전달합니다.
                <br />
                <br />
                작가의 의도대로, 이 작품은 주목받지 못했던 존재의 잠재적
                아름다움을 강조함으로써 우울증 환자들의 자존감 회복에
                기여합니다. "우리 모두가 공작을 품고 있는 소중한 존재"라는
                메시지는 자기 가치에 대한 재인식을 유도하여 우울증의 핵심 증상인
                자기 비하를 완화하는 데 도움을 줍니다.
                <br />
                <br />
                "아카시아 공작"은 이처럼 환경 심리학, 색채 심리학, 예술 치료의
                원리를 세심하게 적용하여 우울증 환자들의 정서적 회복과 심리적
                안정을 돕는 것을 주요 목표로 합니다. 이 작품을 통해 환자들은
                자연의 생명력과 변화의 아름다움을 경험하며, 자신의 내면에 숨겨진
                가능성을 재발견하는 치유의 여정을 시작할 수 있을 것입니다.
                <br />
                <br />- 오늘정신건강학과 의원 양용준 원장
              </ModalText2>
            </>
          ),
        };
      case "아카시아공작 우울증 완화 24_02":
        return {
          image: Byeon02,
          title: "아카시아공작 우울증 완화 24_02",
          description: (
            <>
              <ModalText1>
                <em>Artist's Note : </em> 아카시아 숲속에 누워 하늘을 바라보는
                장면은 대칭적인 구도로 구성되어 있습니다. <br />
                중심에 밝은 태양을 배치하여 희망과 긍정의 메시지를 강조하며,
                자연의 아름다움을 더욱 부각시키고 있습니다. <br />
                전체적으로 시원한 색감을 사용하여 상쾌한 분위기를 자아내며,
                편안함을 더하기 위해 따뜻한 난색 계열의 잎을 풍부하게
                활용하였습니다.
                <br /> 이러한 요소들은 감상자에게 자연의 조화로움과 평화롭고
                안락한 감정을 불러일으킵니다.
                <br />
                <br />{" "}
                <b>
                  효과 : #우울감 개선, #스트레스 감소, #심리 안정, #긍정적 변화,
                  #희망
                </b>
              </ModalText1>
              <br />
              <ModalText2>
                <strong>우울감을 완화시키는 예술적 접근 : </strong> "아카시아
                공작"은 환자의 우울감 개선과 정서적 치유를 목표로, 자연의
                치유력과 예술 치료의 원리를 깊이 있게 융합한 작품입니다. <br />
                <br />이 작품은 밝은 녹색 아카시아 잎사귀들 사이에 숨겨진 화려한
                파란색과 황금색의 공작새 깃털을 섬세하게 표현함으로써, 자연
                이미지의 치유 효과를 극대화합니다. 스티그스도터와 그란(2004)의
                연구에서 밝혀진 바와 같이, 이러한 자연 모티프는 우울증 환자들의
                스트레스를 현저히 감소시키고 전반적인 기분을 개선하는 데 큰
                도움을 줍니다. 특히 병원이나 도시와 같은 인공적 환경에서, 이
                작품은 환자들에게 자연과의 연결감을 제공하여 심리적 안정과
                정서적 회복을 촉진합니다.
                <br />
                <br />
                색채 심리학의 관점에서, 작품에 사용된 녹색은 우울감을 완화하고
                내면의 평화를 유도하는 효과가 있습니다. 공작새 깃털의 파란색과
                황금색은 희망과 활력을 상징하여, 우울한 환자들의 기분을
                고양시키고 긍정적 변화에 대한 기대를 심어줍니다. 이는 색채를
                통한 감정 조절과 기분 전환의 원리를 적용한 것입니다.
                <br />
                <br />
                디에테 등(2003)의 연구결과를 반영하여, 이 작품은 의료 환경을
                더욱 친근하고 편안한 공간으로 변모시키는 역할을 합니다. 일상적인
                잎사귀가 화려한 공작 깃털로 변하는 모습은 환자들에게 변화와
                성장의 가능성을 시각적으로 제시하며, 이는 우울증 치료에서 중요한
                '희망의 메시지'를 전달합니다.
                <br />
                <br />
                작가의 의도대로, 이 작품은 주목받지 못했던 존재의 잠재적
                아름다움을 강조함으로써 우울증 환자들의 자존감 회복에
                기여합니다. "우리 모두가 공작을 품고 있는 소중한 존재"라는
                메시지는 자기 가치에 대한 재인식을 유도하여 우울증의 핵심 증상인
                자기 비하를 완화하는 데 도움을 줍니다.
                <br />
                <br />
                "아카시아 공작"은 이처럼 환경 심리학, 색채 심리학, 예술 치료의
                원리를 세심하게 적용하여 우울증 환자들의 정서적 회복과 심리적
                안정을 돕는 것을 주요 목표로 합니다. 이 작품을 통해 환자들은
                자연의 생명력과 변화의 아름다움을 경험하며, 자신의 내면에 숨겨진
                가능성을 재발견하는 치유의 여정을 시작할 수 있을 것입니다.
                <br />
                <br />- 오늘정신건강학과 의원 양용준 원장
              </ModalText2>
            </>
          ),
        };
      case "아카시아공작 우울증 완화 24_03":
        return {
          image: Byeon03,
          title: "아카시아공작 우울증 완화 24_03",
          description: (
            <>
              <ModalText1>
                <em>Artist's Note : </em> 아카시아 잎이 우상향하는 모습은 기분
                상승을 상징적으로 나타내고 있습니다.
                <br />
                이를 통해 긍정적인 에너지를 전달하며, 차분한 난색 계열의 배경은
                심적 안정감을 조성하는 의도를 담고 있습니다.
                <br />
                <br />{" "}
                <b>
                  효과 : #우울감 개선, #스트레스 감소, #심리 안정, #긍정적 변화,
                  #희망
                </b>
              </ModalText1>
              <br />
              <ModalText2>
                <strong>우울감을 완화시키는 예술적 접근 : </strong> "아카시아
                공작"은 환자의 우울감 개선과 정서적 치유를 목표로, 자연의
                치유력과 예술 치료의 원리를 깊이 있게 융합한 작품입니다. <br />
                <br />이 작품은 밝은 녹색 아카시아 잎사귀들 사이에 숨겨진 화려한
                파란색과 황금색의 공작새 깃털을 섬세하게 표현함으로써, 자연
                이미지의 치유 효과를 극대화합니다. 스티그스도터와 그란(2004)의
                연구에서 밝혀진 바와 같이, 이러한 자연 모티프는 우울증 환자들의
                스트레스를 현저히 감소시키고 전반적인 기분을 개선하는 데 큰
                도움을 줍니다. 특히 병원이나 도시와 같은 인공적 환경에서, 이
                작품은 환자들에게 자연과의 연결감을 제공하여 심리적 안정과
                정서적 회복을 촉진합니다.
                <br />
                <br />
                색채 심리학의 관점에서, 작품에 사용된 녹색은 우울감을 완화하고
                내면의 평화를 유도하는 효과가 있습니다. 공작새 깃털의 파란색과
                황금색은 희망과 활력을 상징하여, 우울한 환자들의 기분을
                고양시키고 긍정적 변화에 대한 기대를 심어줍니다. 이는 색채를
                통한 감정 조절과 기분 전환의 원리를 적용한 것입니다.
                <br />
                <br />
                디에테 등(2003)의 연구결과를 반영하여, 이 작품은 의료 환경을
                더욱 친근하고 편안한 공간으로 변모시키는 역할을 합니다. 일상적인
                잎사귀가 화려한 공작 깃털로 변하는 모습은 환자들에게 변화와
                성장의 가능성을 시각적으로 제시하며, 이는 우울증 치료에서 중요한
                '희망의 메시지'를 전달합니다.
                <br />
                <br />
                작가의 의도대로, 이 작품은 주목받지 못했던 존재의 잠재적
                아름다움을 강조함으로써 우울증 환자들의 자존감 회복에
                기여합니다. "우리 모두가 공작을 품고 있는 소중한 존재"라는
                메시지는 자기 가치에 대한 재인식을 유도하여 우울증의 핵심 증상인
                자기 비하를 완화하는 데 도움을 줍니다.
                <br />
                <br />
                "아카시아 공작"은 이처럼 환경 심리학, 색채 심리학, 예술 치료의
                원리를 세심하게 적용하여 우울증 환자들의 정서적 회복과 심리적
                안정을 돕는 것을 주요 목표로 합니다. 이 작품을 통해 환자들은
                자연의 생명력과 변화의 아름다움을 경험하며, 자신의 내면에 숨겨진
                가능성을 재발견하는 치유의 여정을 시작할 수 있을 것입니다.
                <br />
                <br />- 오늘정신건강학과 의원 양용준 원장
              </ModalText2>
            </>
          ),
        };
      case "아카시아공작 집중력 향상 24_01":
        return {
          image: Byeon05,
          title: "아카시아공작 집중력 향상 24_01",
          description: (
            <>
              <ModalText1>
                <em>Artist's Note : </em> 본 작품은 만다라를 형상화한 도상으로,
                <br /> 아카시아 줄기가 서로 얽혀 있는 복잡한 구조와 곳곳에
                배치되어 있는 공작이 특징입니다. <br />
                줄기가 계속해서 이어지는 형태를 통해 시선을 유도하며, 복잡한
                구조 속에서도 연속성과 통일감을 강조하고 있습니다.
                <br />
                <br />{" "}
                <b>
                  효과 : #집중력 향상, #인지능력 강화, #전두엽 자극, #만다라,
                  #알파파
                </b>
              </ModalText1>
              <br />
              <ModalText2>
                <strong>집중력 향상의 예술적 접근 : </strong> 변진휘 작가의
                ‘집중력 향상’ 연작은 복잡한 패턴과 색상의 대비를 활용하여 시각적
                주의력과 집중력을 향상시킬 수 있는 특성을 가지고 있습니다.
                전체적으로 잎사귀와 눈 모양이 반복적으로 배열된 형태를 이루고
                있어, 대칭성과 반복성이 돋보입니다. 이러한 대칭적이고 반복적인
                패턴은 우리의 뇌가 규칙성을 찾고 이를 인지하도록 자극하여,
                시각적 주의력과 인지적 패턴 인식 능력을 강화시키는 데 도움을
                줍니다. 그림을 관찰하는 동안, 자연스럽게 시선이 중앙으로
                집중되고 시각적 주의가 높아지는 효과를 기대할 수 있습니다. 이는
                규칙을 찾아내는 과정이 뇌의 전두엽을 자극하여 집중력을 유지하는
                데 효과적이기 때문입니다.
                <br />
                <br />
                또한, 이 그림은 강렬한 색상의 대비를 통해 시각적 자극을
                제공합니다. 밝은 초록색 잎사귀와 파란색, 노란색이 혼합된 눈
                모양의 패턴이 선명하게 배치되어 있어, 강렬한 색 대비가
                형성됩니다. 이처럼 대비가 강한 이미지 요소는 시각적 주의력을
                자극하여 특정 요소에 자연스럽게 시선이 집중되도록 유도하며,
                이러한 자극은 뇌의 시각 피질을 활성화하여 주의 집중을
                강화합니다.
                <br />
                <br />
                이 그림의 또 다른 특징은 정교한 기하학적 패턴이 마치 원을 이루는
                형태로 배열되어 있다는 점입니다. 이러한 구도는 만다라와 유사한
                형태로 시선의 흐름을 자연스럽게 중앙으로 집중시키며, 시각적
                탐색을 요구하여 주의력을 끌어올리는 데 효과적입니다.
                기하학적이고 규칙적인 패턴을 시각적으로 분석하는 과정은 단순히
                보는 것을 넘어서 인지적 주의와 지속적인 시각적 탐색을 촉진하고,
                이러한 과정은 시각적 정보 처리 능력을 높이는 데 도움을 줍니다.
                <br />
                <br />
                특히, 이 그림 속에는 반복되는 눈 모양이 군데군데 배치되어 있어
                마치 숨은 패턴을 찾는 듯한 느낌을 줍니다. 이런 숨은 그림 찾기
                방식은 집중력을 요구하며, 시각적 주의 네트워크를 활성화시켜
                시각적 정보 처리 능력과 주의 집중력을 크게 높이는 효과가
                있습니다. 이러한 탐색 활동은 단순히 눈으로 그림을 보는
                것만으로도 주의력 향상에 기여하며, 다양한 연령대에서 집중력
                훈련을 위한 도구로 활용될 수 있습니다.
                <br />
                <br />
                연구에 따르면, 자연적 이미지나 식물 이미지와 같은 시각적 자극은
                뇌의 세타파를 감소시키고 알파파를 증가시켜 더 나은 집중력과
                주의력을 유도합니다. 이 그림은 자연적인 요소인 잎사귀와 강렬한
                시각적 자극을 결합하여, 시각적 주의력과 심리적 안정감을 동시에
                자극할 수 있는 구성을 지니고 있습니다. 이러한 특성은 집중이
                필요한 학습 환경이나 업무 공간에서 심리적 편안함과 주의력을
                높이는 데 효과적일 수 있습니다.
                <br />
                <br />
                전체적으로 이 그림은 복잡한 패턴과 다채로운 색상을 통해 시각적
                몰입을 유도하며, 몰입 상태에서는 외부의 방해 요소에 대한 주의가
                줄어들고 특정 과제나 자극에 대한 집중력이 향상되는 경험을
                제공합니다. 따라서 이 그림은 학습 환경이나 집중이 필요한
                공간에서 시각적 자극으로 활용될 수 있으며, 장시간 집중을 요하는
                작업 중간에 잠깐씩 감상하면 집중력을 유지하는 데 도움이 될 수
                있습니다. 이러한 시각적 자극은 주로 시각적 주의력의 다양한
                측면(선택적 주의, 지속적 주의, 분할 주의 등)을 자극하여, 인지적
                유연성과 전반적인 집중력 향상에 기여할 수 있습니다.
                <br />
                <br />- 오늘정신건강학과 의원 양용준 원장
              </ModalText2>
            </>
          ),
        };
      case "아카시아공작 집중력 향상 24_02":
        return {
          image: Byeon04,
          title: "아카시아공작 집중력 향상 24_02",
          description: (
            <>
              <ModalText1>
                <em>Artist's Note : </em> 본 작품은 만다라를 형상화한 도상으로,
                <br /> 아카시아 줄기가 서로 얽혀 있는 복잡한 구조와 곳곳에
                배치되어 있는 공작이 특징입니다. <br />
                줄기가 계속해서 이어지는 형태를 통해 시선을 유도하며, 복잡한
                구조 속에서도 연속성과 통일감을 강조하고 있습니다.
                <br />
                <br />{" "}
                <b>
                  효과 : #집중력 향상, #인지능력 강화, #전두엽 자극, #만다라,
                  #알파파
                </b>
              </ModalText1>
              <br />
              <ModalText2>
                <strong>집중력 향상의 예술적 접근 : </strong> 변진휘 작가의
                ‘집중력 향상’ 연작은 복잡한 패턴과 색상의 대비를 활용하여 시각적
                주의력과 집중력을 향상시킬 수 있는 특성을 가지고 있습니다.
                전체적으로 잎사귀와 눈 모양이 반복적으로 배열된 형태를 이루고
                있어, 대칭성과 반복성이 돋보입니다. 이러한 대칭적이고 반복적인
                패턴은 우리의 뇌가 규칙성을 찾고 이를 인지하도록 자극하여,
                시각적 주의력과 인지적 패턴 인식 능력을 강화시키는 데 도움을
                줍니다. 그림을 관찰하는 동안, 자연스럽게 시선이 중앙으로
                집중되고 시각적 주의가 높아지는 효과를 기대할 수 있습니다. 이는
                규칙을 찾아내는 과정이 뇌의 전두엽을 자극하여 집중력을 유지하는
                데 효과적이기 때문입니다.
                <br />
                <br />
                또한, 이 그림은 강렬한 색상의 대비를 통해 시각적 자극을
                제공합니다. 밝은 초록색 잎사귀와 파란색, 노란색이 혼합된 눈
                모양의 패턴이 선명하게 배치되어 있어, 강렬한 색 대비가
                형성됩니다. 이처럼 대비가 강한 이미지 요소는 시각적 주의력을
                자극하여 특정 요소에 자연스럽게 시선이 집중되도록 유도하며,
                이러한 자극은 뇌의 시각 피질을 활성화하여 주의 집중을
                강화합니다.
                <br />
                <br />
                이 그림의 또 다른 특징은 정교한 기하학적 패턴이 마치 원을 이루는
                형태로 배열되어 있다는 점입니다. 이러한 구도는 만다라와 유사한
                형태로 시선의 흐름을 자연스럽게 중앙으로 집중시키며, 시각적
                탐색을 요구하여 주의력을 끌어올리는 데 효과적입니다.
                기하학적이고 규칙적인 패턴을 시각적으로 분석하는 과정은 단순히
                보는 것을 넘어서 인지적 주의와 지속적인 시각적 탐색을 촉진하고,
                이러한 과정은 시각적 정보 처리 능력을 높이는 데 도움을 줍니다.
                <br />
                <br />
                특히, 이 그림 속에는 반복되는 눈 모양이 군데군데 배치되어 있어
                마치 숨은 패턴을 찾는 듯한 느낌을 줍니다. 이런 숨은 그림 찾기
                방식은 집중력을 요구하며, 시각적 주의 네트워크를 활성화시켜
                시각적 정보 처리 능력과 주의 집중력을 크게 높이는 효과가
                있습니다. 이러한 탐색 활동은 단순히 눈으로 그림을 보는
                것만으로도 주의력 향상에 기여하며, 다양한 연령대에서 집중력
                훈련을 위한 도구로 활용될 수 있습니다.
                <br />
                <br />
                연구에 따르면, 자연적 이미지나 식물 이미지와 같은 시각적 자극은
                뇌의 세타파를 감소시키고 알파파를 증가시켜 더 나은 집중력과
                주의력을 유도합니다. 이 그림은 자연적인 요소인 잎사귀와 강렬한
                시각적 자극을 결합하여, 시각적 주의력과 심리적 안정감을 동시에
                자극할 수 있는 구성을 지니고 있습니다. 이러한 특성은 집중이
                필요한 학습 환경이나 업무 공간에서 심리적 편안함과 주의력을
                높이는 데 효과적일 수 있습니다.
                <br />
                <br />
                전체적으로 이 그림은 복잡한 패턴과 다채로운 색상을 통해 시각적
                몰입을 유도하며, 몰입 상태에서는 외부의 방해 요소에 대한 주의가
                줄어들고 특정 과제나 자극에 대한 집중력이 향상되는 경험을
                제공합니다. 따라서 이 그림은 학습 환경이나 집중이 필요한
                공간에서 시각적 자극으로 활용될 수 있으며, 장시간 집중을 요하는
                작업 중간에 잠깐씩 감상하면 집중력을 유지하는 데 도움이 될 수
                있습니다. 이러한 시각적 자극은 주로 시각적 주의력의 다양한
                측면(선택적 주의, 지속적 주의, 분할 주의 등)을 자극하여, 인지적
                유연성과 전반적인 집중력 향상에 기여할 수 있습니다.
                <br />
                <br />- 오늘정신건강학과 의원 양용준 원장
              </ModalText2>
            </>
          ),
        };
      default:
        return "";
    }
  };

  return (
    <Container>
      <Header />

      <MainSection>
        <h2>What is Healing Works?</h2>
        <p>
          Healingworks combines the transformative power of art with medical
          expertise to create works that promote inner growth and healing. Our
          unique approach blends scientific analysis with artistic creativity,
          crafting "healing art" that has a positive impact on both mental and
          physical well-being.
          <br />
          <br />
          Art brings inspiration and solace to our lives, infusing creative
          energy that fosters personal growth and emotional restoration.
          <br />
          <br />
          We are dedicated to offering genuine works of art that touch the heart
          and move the soul.
          <br />
          <br />
          <hr />
          <br />
          힐링웍스는 예술의 힘과 의학 지식을 결합하여 내면의 성장과 치유를 돕는
          작품을 만듭니다.
          <br />
          우리의 독특한 접근 방식은 과학적 분석과 예술적 창의성을 결합하여
          정신적 및 신체적 건강에 긍정적인 영향을 미칠 수 있는
          <br />
          '치유 예술'을 창작합니다.
          <br />
          <br />
          예술은 우리의 삶에 감동과 위로를 선사하며, 창조적 영감을 불어넣어
          내면의 성장과 치유를 이끕니다.
          <br />
          <br /> 사람의 마음을 움직일 수 있는 진실된 작품을 선사해드리겠습니다.
        </p>
        <Image src={HWimg} alt="Healing Art" />
      </MainSection>
      <Advisor />
      <ArtistsSection>
        <h2>Meet Our Artists</h2>
        <ArtistCard>
          <ArtistInfo>
            <h3>JUNGBIN HWANG • 황정빈</h3>
            <p>
              <a
                style={{ color: "black", textDecorationLine: "none" }}
                href="https://instagram.com/banihwang"
                target="_blank"
                rel=" noopener noreferrer"
              >
                <IconPositonInsta />
              </a>{" "}
              &nbsp;
              <a
                style={{ color: "black", textDecorationLine: "none" }}
                href="https://www.jungbinhwang.com/"
                target="_blank"
                rel=" noopener noreferrer"
              >
                <IconPositonLink />
              </a>
            </p>
          </ArtistInfo>
          <p>
            Specializes in abstract expressionism with a focus on mental health.
          </p>
          <Artworks>
            <ImageBox onClick={() => openModal("SLEEPING CHINCHILLA")}>
              <ArtImage src={Hwang01} alt="Sleeping Chinchilla." />
              <p>
                JUNGBIN HWANG (1991) / SLEEPING CHINCHILLA <br />
                72.7cm X 53.0cm(20P) / OIL ON CANVAS / 2024
                <br />
                <br />
                #숙면 #안정 #수면유도 #이완 #멜라토닌 분비
                <br />
                <br /> <b>4,000,000 KRW</b>
                <br />
                <DetailsButton onClick={() => openModal("SLEEPING CHINCHILLA")}>
                  Details
                </DetailsButton>
              </p>
              <SoldOutBadge>SOLD OUT</SoldOutBadge>
            </ImageBox>
            <ImageBox onClick={() => openModal("Feast")}>
              <ArtImage src={Hwang02} alt="Feast." />
              <p>
                JUNGBIN HWANG (1991) / Feast <br />
                72.7cm X 53.0cm(20P) / OIL ON CANVAS / 2024
                <br />
                <br />
                #식욕 #행복감 #맛 #신선함 #따뜻함 #소중함 #즐거움 #입맛
                <br />
                <br /> <b>4,000,000 KRW</b>
                <br />
                <DetailsButton onClick={() => openModal("Feast")}>
                  Details
                </DetailsButton>
              </p>
            </ImageBox>
          </Artworks>
        </ArtistCard>
        <ArtistCard>
          <ArtistInfo>
            <h3>JINHWI BYEON • 변진휘</h3>
            <p>
              <a
                style={{ color: "black", textDecorationLine: "none" }}
                href="https://instagram.com/minhwa_genie"
                target="_blank"
                rel=" noopener noreferrer"
              >
                <IconPositonInsta />
              </a>{" "}
              &nbsp;
              <a
                style={{ color: "black", textDecorationLine: "none" }}
                href="https://marpple.shop/kr/minhwa_genie/"
                target="_blank"
                rel=" noopener noreferrer"
              >
                <IconPositonLink />
              </a>
            </p>
          </ArtistInfo>
          <p>Combines landscape painting with therapeutic color theory.</p>
          <Artworks>
            <ImageBox
              onClick={() => openModal("아카시아공작 우울증 완화 24_01")}
            >
              <ArtImage
                src={Byeon01}
                alt="Acacia Peacock_Improvement of depression 24_01."
              />
              <p>
                변진휘 (1992) / 아카시아공작 우울증완화 24-01
                <br /> 순지에 분채,봉채 / 80.3cm X 65.1cm(25F) / 2024
                <br />
                <br />
                #우울감 개선 #스트레스 감소 #심리 안정 #긍정적 변화 #희망
                <br />
                <br />
                <b>5,000,000 KRW</b>
                <br />
                <DetailsButton
                  onClick={() => openModal("아카시아공작 우울증 완화 24_01")}
                >
                  Details
                </DetailsButton>
              </p>
            </ImageBox>
            <ImageBox
              onClick={() => openModal("아카시아공작 우울증 완화 24_02")}
            >
              <ArtImage
                src={Byeon02}
                alt="Acacia Peacock_Improved concentration_24_02."
              />
              <p>
                변진휘 (1992) / 아카시아공작 우울증완화 24-02
                <br /> 장지에 분채 / 72.7cm x 60.6cm(20F) / 2024
                <br />
                <br />
                #우울감 개선 #스트레스 감소 #심리 안정 #긍정적 변화 #희망
                <br />
                <br />
                <b>4,000,000 KRW</b>
                <br />
                <DetailsButton
                  onClick={() => openModal("아카시아공작 우울증 완화 24_02")}
                >
                  Details
                </DetailsButton>
              </p>
            </ImageBox>
            <ImageBox
              onClick={() => openModal("아카시아공작 우울증 완화 24_03")}
            >
              <ArtImage
                src={Byeon03}
                alt="Acacia Peacock_Improvement of depression 24_03."
              />
              <p>
                변진휘 (1992) / 아카시아공작 우울증완화 24-03
                <br /> 순지에 분채 / 53.0cm x 65.1cm(15F) / 2024
                <br />
                <br />
                #우울감 개선 #스트레스 감소 #심리 안정 #긍정적 변화 #희망
                <br />
                <br /> <b>3,000,000 KRW</b>
                <br />
                <DetailsButton
                  onClick={() => openModal("아카시아공작 우울증 완화 24_03")}
                >
                  Details
                </DetailsButton>
              </p>
              <SoldOutBadge>SOLD OUT</SoldOutBadge>
            </ImageBox>
            <br />
            <ImageBox
              onClick={() => openModal("아카시아공작 집중력 향상 24_01")}
            >
              <ArtImage
                src={Byeon05}
                alt="Acacia Peacock_Improved concentration_24_01."
              />
              <p>
                변진휘 (1992) / 아카시아공작 집중력향상 24-01
                <br /> 장지에 분채 / 60.6cm x 72.7cm(20F) / 2024
                <br />
                <br />
                #집중력 향상 #인지능력 강화 #전두엽 자극 #만다라 #알파파
                <br />
                <br />
                <b>4,000,000 KRW</b>
                <br />
                <DetailsButton
                  onClick={() => openModal("아카시아공작 집중력 향상 24_01")}
                >
                  Details
                </DetailsButton>
              </p>
            </ImageBox>
            <ImageBox
              onClick={() => openModal("아카시아공작 집중력 향상 24_02")}
            >
              <ArtImage
                src={Byeon04}
                alt="Acacia Peacock_Improved concentration_24_02."
              />
              <p>
                변진휘 (1992) / 아카시아공작 집중력향상 24-02
                <br /> 순지에 분채 / 53.0cm x 65.1cm(15F) / 2024
                <br />
                <br />
                #집중력 향상 #인지능력 강화 #전두엽 자극 #만다라 #알파파
                <br />
                <br />
                <b>3,000,000 KRW</b>
                <br />
                <DetailsButton
                  onClick={() => openModal("아카시아공작 집중력 향상 24_02")}
                >
                  Details
                </DetailsButton>
              </p>
            </ImageBox>
          </Artworks>
        </ArtistCard>

        <Modal isOpen={isModalOpen} onClick={handleModalClickOutside}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <ModalImage image={selectedArtwork?.image} />
            <ModalTextSection ref={modalTextRef}>
              <h3>{selectedArtwork?.title}</h3>
              {selectedArtwork?.description}
              <CloseButton onClick={closeModal}>Close</CloseButton>
            </ModalTextSection>
          </ModalContent>
        </Modal>
      </ArtistsSection>

      <HealingEffectsSection>
        <h2>Healing Effects</h2>
        <p>
          Our art is specifically designed to aid in the treatment of conditions
          such as insomnia, depression, ADHD, and anxiety. Each piece is
          intentionally created based on medical evidence to enhance its
          therapeutic effects.
          <br />
          <br /> We plan to actively continue our ESG-oriented social
          contribution activities by utilizing original and print artworks with
          healing effects. In close collaboration with Backersby, we aim to
          offer fans new experiences to engage with the creative process.
          Through social networking features, users will be able to interact
          with various celebrities, continuously support and share the artist’s
          daily life and creative activities, and build new communities. We
          intend to widely promote our social contribution efforts and artistic
          values while continuously strengthening collaboration with various
          stakeholders.
          <br />
          <br />
          Healingworks is committed to bringing peace of mind to more people
          through ongoing collaborations with renowned artists.
          <br />
          <br />
          <hr />
          <br />
          <br />
          힐링웍스의 예술은 불면증, 우울증, ADHD, 불안 장애와 같은 질환 치료에
          도움을 주기 위해 특별히 디자인하고 있습니다.
          <br />각 작품은 치료 효과를 높이기 위해 의학적 근거를 바탕으로
          의도적으로 제작되었습니다.
          <br />
          <br />
          치유 효과가 있는 원화 및 판화를 활용하여 ESG 개념의 사회공헌 활동을
          적극적으로 이어가고자 Backersby와 긴밀히 협업을 통하여 팬들로부터 창작
          활동에 대해 교류할 수 있는 새로운 경험을 제공할 예정입니다. 소셜
          네트워크 기능을 통하여 다양한 셀러브리티와 소통할 수 있으며, 작가의
          일상 및 작품 활동을 지속적으로 후원하고 공유받으며 새로운 커뮤니티를
          만들어 갈 예정입니다. 우리의 사회공헌 활동과 예술적 가치를 널리
          알리고, 다양한 이해관계자들과의 협력을 지속적으로 강화할 예정입니다.
          <br />
          <br />
          힐링웍스는 유명 작가들과 지속적인 협업을 통하여 더 많은 이들에게
          마음의 안식을 찾아드리고자 합니다.
        </p>
      </HealingEffectsSection>

      <Footer />
    </Container>
  );
};

export default App;
